import Vue from "vue";

Vue.filter('price' , function(num) {
    if(num) {
        // let englishNumber = this.convertToEnglishNumber(num);
        const result = num.toString()
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return result;
    }
})

Vue.filter('persianDate' , function(date) {
    return window.moment(date).format('HH:mm jYYYY/jMM/jDD ')
})