<template>
  <div id="app">
    <MediaQueryProvider :queries="$options.queries">
      <notifications group="error" position="top right" />
      <notifications group="success" position="top right" />
      <notifications group="warning" position="top center" />
        <router-view ></router-view>
      <transition name="fade">
<!--        <div class="loading-spinner" v-if="$store.state.loading">-->
<!--          <div class="loading-spinner-div" style="margin-top:auto;height:100%">-->
<!--            <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="60" :color="$root.colorLoading" />-->
<!--          </div>-->
<!--  </div>-->
        <div v-if="$store.state.loading" class="loading-spinner">
          <img src="./assets/logo.png" alt="">
          <!-- <img class="loading-img" :src="require('./assets/images/loading.gif')" alt="Loading..."> -->
        </div>
      </transition>
    </MediaQueryProvider>
  </div>
</template>
<script>
import { MediaQueryProvider } from "vue-component-media-queries";
const queries = {
  mobileSize: "(max-width: 768px)",
  xxSmall: "(max-width: 420px)",
  xSmall: "(max-width: 576px)",
  small: "(min-width: 576px)",
  medium: "(max-width: 768px)",
  large: "(max-width: 992px)",
  xLarge: "(min-width: 992px)",
  xxLarge: "(min-width: 1200px)",
  xxxLarge: "(min-width: 1399px)"
};

export default {
  queries,
  components: {
    MediaQueryProvider,
  },
};
</script>
<style scoped>
  .loading-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
  }

  .loading-spinner {
    background-color: rgb(188 188 188 / 63%);
  }
  .loading-spinner {
    display: flex;
    align-items: center;
  }
  .loading-spinner img {
    width: max(40% , 180px);
    height: auto;
  }
</style>
